table.ant-table {
    font-size: 16px;
  }
  .ant-table-thead > tr > th {
    height: 16px;
    font-weight: bold;
    padding: 17px;
  }
  /* row data */
  .ant-table-tbody > tr > td {
    height: 13px;
    padding: 9px;
  }
 
 .registercorporateform .ant-form .ant-form-item {
    margin-bottom: 0px;
  }
 .main_btns .ant-btn{
    margin-top:1%;
    margin-left:1%;
    background-color: #F4AD33;
    color:white
 }

 .scrollable {
  overflow: scroll;
}
.scrollable::-webkit-scrollbar {
   width: 6px;
}
.scrollable::-webkit-scrollbar-track {
   background: #f1f1f1; 
 }
  
.scrollable::-webkit-scrollbar-thumb {
   background: #888; 
 }
 
 .scrollable::-webkit-scrollbar-thumb:hover {
   background: #555; 
 }
.main{
   height:30vh;
   background:#FAFAFA;
}
.main>h3{
   font-size: 35px;
   padding-top: 30px;
}
.main>h3,p{
   text-align: center;
   align-items: center;
   color:gray;
}
.landing_page>h6{
   color: gray;
   text-align: center;
   margin-top: 4px;
}

.ant-upload-list-item-invalid {
   border-color: red;
 }