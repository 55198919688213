@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* scroller if the page size is larger than the screen */
::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #f1f1f1;
  /* creates padding around scroll thumb */
}
