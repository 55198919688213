
.ant-menu>.ant-menu-item{
  left: 70%;
  /* margin-top: 220px; */
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  }
  .product_menu .ant-menu>.ant-menu-item{
    left:1%;
    font-size: 10px;
    color: #8C8C8C;
    font-weight: bold;
    }
    .product_menu .ant-menu{
      width:300px;  
    }
  .ant-menu>.ant-menu-item:hover{
    background-color: none;
  }
  
  .user_input{
    width:350px;
    margin-left: 300px;
    margin-top: -45px;
   
  }
  ::placeholder{
    font-weight: 100;
    color: #000;
  }
  .search {
    margin-left: 620px;
    margin-top: -25px;
    size: 30px;
    opacity: 1;
  
    position: relative;
   }
   .search .fa-solid{
     font-size: 20px;
     background-color: #F4AD33;
   
      z-index: 10;
   }
   .ant-menu>.ant-menu-item span .span{
    color: #F4AD33;
  }
  
  .logo a{
  margin-left: 110px;
  margin-top: 8px;
  float: left;
  color: #fff;
  font-size: 25px;
  }
  .logo a span{
    font-weight: bold;
    
  }
  .dropdown {
    margin-left: 50px;
    margin-top: -30px;
    color: #fff;
  
  }
  .container-fluid{
   align-items: center;
    max-height: 170px;
  }
  
  .header{
    height: 120px;
    background-color: #021529;
    width: 100%;
  }
  
  hr {
    height: 0.1px;
    background: #fff;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
  }
  .anticon anticon-search{
    background-color: #F4AD33;
    color: #000;
  }
  .icons{
    margin-top: -20px;
    margin-left: 1220px;
  
  }
  .icons .fa-solid{
    font-size: 25px;
    margin-left: 20px;
  }
  .logo .img{
    width: 60px;
    
    height: 60px;
    margin-left: -240px;
  }
  .products{
    margin-top: 40px;
   }
  
  Col
  {
    background-color: #fff;
  }
  
  .product_card h2
  {
    margin-left: 80px;
    font-size: 16px;
    margin-top: -10px;
  }
  .card_1 .h2_1{
    margin-top: -50px;
  }
  .product_card a
  {
    margin-top: 700px;
  }
  .product_card 
  {
    margin-left: 300px;
    margin-top: -30px;
  }
  .product_card h1
  {
    margin-left: 300px;
    font-size: 24px;
    margin-top: -10px;
  
  }
  .product_card h4 {
    margin-left: 300px;
    font-size: 24px;
    margin-top: -60px;
    font-size: 14px;
  
  }
  .product_card a
  {
  margin-left: 160px;
  margin-top: 35px;
  color: #F4AD33;
  top: 20px;
  font-size: 14px;
  }
  .product_card .one
  {
    margin-left: 0px;
    margin-top: 300px;
    padding-bottom: 20px;
    font-size: 14px;
  }
  .card_1{
    margin-left: 50px;
  }
  .card_content
  {
    margin-top: 50px;
  }
  .card{
    margin-left: 30px;
  }
  
  hr
  {
    background-color: #F4AD33;
    margin-top: 50px;
    width: 114%;
    margin-left: -28px;
   
  }
  .card_content:hover
  {
    border-color: #3099FB;
  }
  .card_2
  {
    margin-left: 510px;
    margin-top: -200px;
    }
  
  .card_2 button
  {
    height: 40px;
    margin-left: 0px;
    top: -50px;
    font-size: 12px;
    color: #E94042;
    background-color: #F9DEDB;
    border-color: #F1A29D;
    font-weight: bold;
  }
  .card_2 h1{
    margin-left: 0px;
    font-size: 24px;
    margin-top: -35px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
   
  }
  .card_2 .fa-solid{
    margin-left: 20px;
     top: 0px;
     color: #F4AD33;
     font-size: 20px;
  }
  .stars{
    margin-left: 100px;
  }
  .card_2 p{
    font-family: 'Times New Roman', Times, serif;
    margin-left: 10px;
    margin-top: -20px;
    
    font-size: 12px;
  }
  .bag .fa-bag-shopping{
    color: #000;
    margin-top: 10px;
    margin-left: 180px;
  }
  .card_2 img{
    margin-left:202px;
    margin-top: -210px;
    height: 190px;
    width: 200px;
  }
  
  .card_3{
    margin-left: 970px;
    margin-top: -200px;
  }
  .card_3 button 
  {
    height: 40px;
    margin-left: 0px;
    top: -50px;
    color: #E94042;
    background-color: #F9DEDB;
    font-size: 12px;
    border-color: #F1A29D;
    font-weight: bold;
  }
  
  .card_3 h1 
  {
    margin-left: 0px;
    font-size: 24px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    margin-top: -35px;
  
  }
  
  .card_3 .fa-solid 
  {
    margin-left: 20px;
    top: -20px;
    color: #F4AD33;
    font-size: 20px;
  }
  .stars
   {
    margin-left: -15px;
  }
  .card_3 p 
  {
    font-family: 'Times New Roman', Times, serif;
    margin-left: 10px;
    margin-top: -20px;
    font-size: 12px;
  }
  .card_3 img 
  {
    margin-left: 250px;
    margin-top: -230px;
    height: 150px;
    width: 150px;
  }
  .bag_2 .fa-bag-shopping 
  {
    color: #000;
    margin-top: 10px;
    margin-left: 180px;
  }
  /* All Products */
  .container-fluid .all_products
  {
    background-color: #FAFAFA;
    width: 100%;
    height: 1000px;
  }
  .all_product
  {
    /* margin-top: 150px; */
    margin-left:320px;
    padding-top: 150px;
    background-color: #FAFAFA;
  }
  .all_products p{
    margin-left: -10px;
    /* margin-top: -730px; */
    font-size: 20px;
   
  }
  .all_product img
  {
     width: 210px;
     height: 210px;
     /* margin-top: -1px; */
     margin-left: 1px;
  }
  .all_product title
  {
    margin-left: -200px;
  }
  .all_product .stars
  {
    margin-left: -21px;
    
  }
  .all_product .stars .fa-solid
  {
  color: #F4AD33;
  margin-left: 20px;
  margin-top: 20px;
  }
  .all_product p
  {
     margin-top: -32px;
  }
  .all_product .bag 
  {
    margin-top: -20px;
    margin-left: -190px;
    
    
  }
  .all_product col
  {
    margin-left: -200px;
  }
  /* Footer */
  .footer .part_1
  {
    margin-top: 400px;
    margin-left: 71px;
  }
  .part_1 h1{
    font-size: 25px;
    font-weight: bold;
    
  
  }
  .part_1 input{
    margin-top: 20px;
    padding-left: 250px;
  }
  .part_1 button{
    margin-left: 260px;
    top: -32px;
    background-color: #000;
    width: 140px;
  }
  ::placeholder{
    color: #000;
  }
  .part_2{
    margin-left: 600px;
    margin-top: -180px;
  }
  .part_2 h3{
    font-weight: bold;
   width: 150px;
    font-size: 20px;
    
  }
  .part_2 h5{
    width: 150px;
   margin-top: 15px;
   font-size: 15px;
  }
  .part_3{
    margin-top: -150px;
  }
  
  .side_nav{
    margin-left: 40px;
    font-size: 20px;
   
  }
  .side_nav ul .main {
    margin-top: -170px;
    font-weight: bold;
    font-size: 30px;
  }
  
  .side_nav ul li {
   
    text-decoration: none;
      list-style-type: none;
      padding-top: 30px;
  }
  .side_nav ul li a{
    color: #000;
    font-weight: 100;
    padding-top: 20px;
  }
  .side_nav ul .sub{
    margin-top: 90px;
    color: #F4AD33;
    
  }
  .side_nav ul li:hover{
    text-decoration:blueviolet
  }
  .top{
    margin-left: 1100px;
    margin-top: -0px;
  }
  .top .h5{
  margin-top: -50px;
  width: 150px;
  font-size: 20px;
  font-weight: bold;
  }
  .top .h3 {
  margin-left: 200px;
  margin-top: -28px;
  font-size: 20px;
  font-weight: bold;
  }
  .top .vector{
    margin-top: -35px;
    margin-left: 150px;
  
  }
  .top .vector_1 {
    margin-left: 280px;
    background-color: #000;
    margin-top: -42px;
  
  }
  .get_product2 h1{
    margin-left: 10px;
    margin-top: 20px;
    font-weight: bold;
  }
  .get_product2 button{
    margin-top: 10px;
    margin-left: 10px;
    height: 50px;
    font-weight: bold;
    background-color: #F4AD33;
  }
  .header2 button{
    margin-left: 1070px;
    top: -35px;
    height: 40px;
    background-color: #fff;
    color: #000;
    font-weight: bold;
  }.buttons{
    margin-left: 930px;
    margin-top: 50px;
    border: 1px solid #000;
    width: 150px;
    height: 36px;
  
  
  }
  .buttons .main_btn{
  
  margin-left: 107px;
  margin-top: 0px;
  
  
  }
  .buttons .sub_1{
    margin-top: -32px;
    margin-left: 0px;
  }
  .buttons p{
    margin-left: 70px;
    margin-top: -27px;
   }
   .last_button{
     margin-left: 930px;
     margin-top: 50px;
  
   }
   .secondary{
     margin-left: 30px;
     width: 150px;
    height: 50px;
   }
   .primary{
     width: 230px;
     height: 50px;
   }
   .last_button .fa-solid{
     margin-left: -70px;
     
   }
   .main_products{
     margin-top: 200px;
     margin-left: 30px;
   }
  
  
  
   .main_products title {
     margin-left: -100px;
   }
  
   .main_products .stars {
     margin-left: -21px;
   }
  
   .main_products .stars .fa-solid {
     color: #F4AD33;
     margin-left: 20px;
     margin-top: 20px;
   }
  
   .main_products p {
     margin-top: -20px;
   }
  
   .main_products .bag .fa-solid {
     margin-top: 20px;
     margin-left: 180px;
   }
  .last_products{
    margin-top: 250px;
  }
  
  .login_header{
    margin-left: 45%;
    margin-top: 9%;
    width: 400px;
  }
  .login_header input{
  padding-top: 20px;
  
  }
  .login_header .user_name{
    margin-left: -120px;
    margin-top: 40px;
    height: 50px;
  }
  .login_header .pass{
      margin-left: -120px;
        margin-top: 40px;
        height: 50px;
  }
  .login_header button{
    margin-top: 30px;
   margin-left: -120px;
  }
  .login_header .ant-btn{
  
  }
  .login_header h2{
    font-size: 25px;
    margin-left: 30px;
  }
  .login_header p{
    margin-left: -30px;
  }
  .login_header .links{
    margin-top: 20px;
    color: #000;
   
  }
  .login_header  .links .forget_pass{
  padding-top: 220px;
  color: #000;
  }
  .login_header .links .register {
    padding-top: 220px;
    color: #000;
  }
  .login_header .links .register_page {
    padding-top: 220px;
    color: #000;
  }
  .links .links a{
    padding-bottom: 20px;
    color: #000;
    
  }
  .admin Header{
    height: 100px;
  }
  /* .admin img{
    margin-top: 30px;
  } */
  .admin a{
    margin-top: 30px;
  }
  .admin h1{
    margin-top: -70px;
    margin-left: 1000px;
    color: #F4AD33;
    font-size: 20px;
  }
  .admin h2 {
    margin-top: -10px;
    margin-left: 1100px;
    color: #fff;
    font-size: 20px;
  }
  .header_corner img{
    margin-left: 1210px;
    margin-top: -120px;
  }
  .header_corner .setting{
    margin-top: -140px;
    margin-left: 1300px;
    z-index: 10;
    
  }
  .header_corner .setting .fa-solid{
    color: #fff;
    font-size: 35px;
  }
  /* .tabs .items{
    margin-left: 00px;
  }
  .tabs{
    height: 300px;
  }
  .ant-tabs-tab-btn{
    color: #000;
    margin-left: 80px;
    height: 50px;
    margin-top: 30px;
  }
  .ant-tabs-tab.ant-tabs-tab-active{
    color: #000;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #000;
    font-size: 30px;
    margin-top: -5px;
  }
  .tabs button{
    margin-left: 90px;
    height: 50px;
    color: #fff;
    
    background-color: #F4AD33;
  
  } */
  .table{
    width: 70%;
    margin-left: 40px;
    margin-top: 50px;
  }
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th{
    color: #000;
  }
  .ant-table-thead>tr> {
   background: #3071b9 !important;
  }
  .permission .app_permission{
    margin-left: 150px;
    margin-top: 100px;
  }
  .app_permission h1{
    margin-left: 200px;
    margin-top: 100px;
  }
  .app_permission button{
    margin-left: 200px;
  }
  .app_permission img{
    margin-left: 280px;
    margin-top: -140px;
  }
  
  .new_products Header {
    height: 100px;
  }
  
  .new_products img {
    margin-top: 30px;
  }
  
  .new_products a {
    margin-top: 30px;
  }
  
  .new_products h1 {
    margin-top: -80px;
    margin-left: 1000px;
    color: #F4AD33;
    font-size: 20px;
  }
  
  .new_products h2 {
    margin-top: -50px;
    margin-left: 1100px;
    color: #fff;
    font-size: 20px;
  }
  
  .header_corner img {
    margin-left: 1210px;
    margin-top: -160px;
  }
  
  .header_corner .setting {
    margin-top: -140px;
    margin-left: 1300px;
    z-index: 10;
  
  }
  
  .header_corner .setting .fa-solid {
    color: #fff;
    font-size: 35px;
  }
  .new_products .input input{
    width: 350px;
    margin-left: 250px;
    height: 40px;
    top: -45px;
  
  }
  .new_products .search {
    margin-left: 20%;
    margin-top: -25px;
    size: 30px;
    opacity: 1;
  
    /* position: relative; */
  }
  
   .new_products .search .fa-solid {
    z-index: 10;
  }
  .btn {
    margin-left:38%;
    margin-top: -150px;
   
  }
  
  .btn button{
    height: 40px;
    width: 50px;
  }
  .new_products {
    background-color: #FAFAFA;
    height: 1400px;
  }
  .new_products .items{
    margin-top: 0%;
    margin-left: 3%;
  }
  .new_products .items .product_menu{
    margin-left: 2%;
  }
   .items .stars {
     margin-left: -21px;
   }
  
   .items .stars .fa-solid {
     color: #F4AD33;
     margin-left: 20px;
     margin-top: 20px;
   }
  
   .items p {
     margin-top: -20px;
   }
  
   .items .edit{
     margin-top: 20px;
     margin-left: 90px;
   }
   .items .delete {
        margin-top: -25px;
        margin-left: 130px;
      }
   /* .new_items .container-fluid{
        height: 1200px;
      } */
  .new_items Header{
    border-bottom: 1px solid #000;
    margin-top: 0px;
  }
  .new_items a{
    margin-left: 20px;
    color: #000;
    font-size: 20px;
    cursor: pointer;
  }
  .new_items .ant-layout-header i{
    margin-left: 200px;
  }
  
  .client_register Header {
    border-bottom: 1px solid #000;
  
  }
  
  .client_register a {
    margin-left: 20px;
    color: #000;
    font-size: 20px;
    cursor: pointer;
  }
  
  /* .new_items .vertical_line{
    border-left: 1px solid #000;
    height: 1000px;
    margin-left: 300px;
    margin-top: 0px;
  } */
  .new_items .ant-layout-header{
    margin-top: -30px;
    border-bottom:solid 1px #BFBFBF ;
  }
  .input_area{
    margin-left: -50px;
  width: 27%;
  }
  .input_area textarea{
    margin-top: 20px;
    margin-left: -300px;
    padding-top: 30px;
   
  
  }
  .input_area input{
    width: 400px;
    
   
  
   
  }
  ::placeholder{
    color: #000;
    font-weight: bold;
  
  }
  .item_pics h2{
    margin-left: 0%;
    margin-top: 1%;
  }
  .item_pics .card_1{
    margin-top: -120px;
    margin-left: 170px;
  }
  
  .item_pics .delete{
    margin-top: -178px;
    margin-left: 108px;
    }
  .item_pics .card_2{
        margin-top: -130px;
        margin-left: 350px;
  }
  .item_pics .card_2 img{
    margin-top: 0px;
    margin-left: 10px;
    width: 130px;
    height: 130px;
  }
  .card_2 .delete{
    margin-top: -180px;
    margin-left: 110px;
  }
  .item_pics .first_card{
    padding-top: 20px;
  }
  .item_pics .row{
  margin-top: 50px;
  }
  .page_input h2{
    margin-left: 5px;
  }
  .page_input{
    margin-left: 420px;
    margin-top: -680px;
   
  }
  .page_input .birr{
   width : 300px;
   height: 60px;
  }
  .page_input .birrs {
    width: 300px;
    height: 60px;
  }
  
  .page_input .number{
    margin-top: 15px;
   
  }
  input[type="text"][value="ብር"]{
   padding-left: 350px;
  }
  input[type="text"][value="50 "] {
    padding-left: 50px;
  }
  ::placeholder{
   
    font-size: 15px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
  }
  
  .birr .h_2 h2{
    margin-top: 20px;
    z-index: 10;
  }
  .switch {
    margin-left: 360px;
    margin-top: -40px;
    font-size: large;
  }
  .switch2 {
    
    margin-top: 40px;
    font-size: large;
  }
  .select_team {
    margin-top: 50px;
    height: 100px;
   
  }
  .select_team .select-after {
    width: 350px;
   }
   .select_team option{
     height: 200px;
   }
   .select_team button{
     margin-left: 10px;
     height: 50px;
     width: 50px;
     border-color: #F4AD33;
   }
  .selected_items button{
    width: 100px;
    margin-top: -500px;
    background-color: #FAFAFA;
  
    
  }
  
  .selected_items{
    margin-top: -40px;
  }
  .selected_items .btn_two{
    margin-left: 10px;
    width: 150px;
  }
  .deliver h2{
    margin-top: 50px;
    margin-left: -100px;
  }
  .deliver .select-after {
    margin-top: -100px;
    
  }
  .select_team {
    margin-top: 50px;
    height: 100px;
  
  }
  
  .deliver .select-after {
    width: 350px;
  }
  
  .deliver option {
    height: 200px;
  }
  
  .deliver button {
    margin-left: 10px;
    height: 50px;
    width: 50px;
    border-color: #F4AD33;
  }
  .size{
    margin-top: -80px;
  }
  .size  .selected_items{
    margin-left: 100px;
    margin-top: 0px;
  }
  .size button{
    border-color: #F4AD33;
    height: 60px;
    width: 60px;
    margin-top: 10px;
  }
  
  .size2 {
    margin-top: -60px;
  }
  
  .size2 .selected_items {
    margin-left: 100px;
    margin-top: -25px;
  }
  
  .size2 button {
    border-color: #F4AD33;
    height: 60px;
    width: 60px;
    margin-top: 0px;
    margin-left: 5px;
  }
  .selected_item{
    margin-left: 80px;
  }
  .selected_item .btn_two{
    margin-left: 30px;
  }
  .selected_item .btn_three{
    margin-top: 10px;
  }
  .new_items .border_bottom{
    border-bottom: 1px solid #000;
    margin-top: 150px;
    margin-left: -400px;
  }
  .bottom_btn{
    margin-top: 30px;
    margin-left: -290px;
  
  
  }
  .bottom_btn button{
    width: 50%;
    height: 50px;
    color: #fff;
    background-color: #F4AD33;
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
  }
  .border_bottom2{
      border-bottom: 1px solid #000;
        margin-top: 265px;
        margin-left: -900px;
  }
  .bottom_btn2 {
    margin-top: 70px;
    margin-left: -120px;
  }
  .bottom_btn2 button{
    width: 40%;
    height: 40px;
    color: #fff;
    background-color: #F4AD33;
    font-size: 20px;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
  }
  .company_register .container-fluid{
    width: 160%;
    height: 1150px;
    margin-left: -60%;
    margin-top: -20%;
    background-color: #FAFAFA;
  
   
   
  }
  .tabs button:hover{
  background-color: #F4AD33;
  
  }
  .drawer_ .input_area{
    margin-left: 0%;
  }
  
  .drawer_ .input_area h2{
    margin-left: 2%;
    margin-top: 10%;
    color: #000;
  }
  /* .drawer_ .input_area .ant-input{
    margin-left: -20%;
  
  } */
  .company_register .deliver h2{
    margin-left: 54px;
  }
  .company_register .deliver .select-after{
    margin-left: 50px;
    width: 400px;
    margin-top: 10px;
   
  }
  .company_register .border_bottom{
    margin-top: 200px;
    margin-left: 0px;
  }
  .company_register .bottom_btn{
    margin-left: 50px;
  }
  .company_register .bottom_btn button{
    width: 400px;
  }
  .company_register {
    display: none;
  }
  
  .company_register .close {
    margin-left: 85%;
    margin-top: -18%;
  }
  
  .company_register Header {
    border-bottom: 1px solid #000;
  
  }
  
  .new_class {
    transform: translateX(-10%);
    transition: transform 2s;
  
   
  }
  
  /* .company_register:hover{
    transform: translateX(100%);
      transition: transform 1s;
  } */
  .company_register a {
    margin-left: 40px;
    color: #000;
    font-size: 20px;
    cursor: pointer;
  }
  .client_register .container-fluid {
    width: 35%;
    height: 1250px;
    margin-left: 490px;
  
    background-color: #fff;
  
  }
  
  
  .client_register {
    background-color: #F0F0F0;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .client_register .input_area h2 {
    margin-left: 100px;
    margin-top: 40px;
  }
  
  .client_register .deliver h2 {
    margin-left: 54px;
  }
  
  .client_register .deliver .select-after {
    margin-left: 50px;
    width: 400px;
    margin-top: 10px;
  
  }
  
  .client_register .border_bottom {
    margin-top: 200px;
    margin-left: 0px;
  }
  
  .client_register .bottom_btn {
    margin-left: 50px;
  }
  
  .client_register .bottom_btn button {
    width: 400px;
  }
  .tabs .link{
    color: #000;
   margin-top: -100px;
   margin-left: 200px;
   background-color: #F0F0F0;
  }
  .tabs button{
    color: #000;
  }
  .tabs button:hover{
    color: #3099FB;
    text-decoration: underline;
    font-size: large;
  }
  .container .table{
    width: 67%;
  }
  .container .table thead th{
    margin-left: 25%;
    width: 40%;
    padding-top: 2%;
    padding-bottom: 2%;
      
  }
  .container .table tbody td{
  padding-top: 1%;
  font-family:'Times New Roman', Times, serif;
  font-size: 20px;
  font-weight:500;
  padding-bottom: 1%;
  }
  .container .table thead{
    background-color: #FAFAFA;
   
  }
  .container .table tbody:hover{
     background-color: #FAFAFA;
  }
  
  
  
  .cart_page .content{
    margin-left: 10%;
  }
  .cart_page h5{
    margin-top: 2%;
    
  }
  .cart_page .content a{
    margin-left: 2%;
    margin-top: -4%;
    text-decoration: underline;
    color: #F4AD33;
  }
  .product_card .bottom_border{
    border-bottom: 1px solid #000;
    background-color: #FAFAFA;
    padding-bottom: 2%;
    padding-top: -20px;
    margin-left: -3%;
    width: 106%;
  }
  .product_card .delete_btn{
    margin-top: -6%;
    margin-left: 97%;
  }
  .product_card header{
    margin-top: -6%;
    margin-left: -3%;
    width: 106%;
    height: 40px;
    background-color: #FAFAFA;
    }
    .content .description{
      margin-left: 30%;
      margin-top: -25%;
    }
    .content .buttons{
      margin-top: -6%;
      margin-left: 80%;
    }
    .content .orders{
      margin-left: 65%;
      margin-top: -53%;
    }
    .content .orders header{
      margin-top: -7%;
        margin-left: -6%;
        width: 110%;
        height: 20px;
        background-color: #fff;
    }
  .content .orders .bottom_border{
    border-bottom: 1px solid #000;
      background-color: #fff;
      padding-bottom: 2%;
      padding-top: -22px;
      margin-left: -6%;
      width: 112%;
  }
  .content .orders .third_card .bottom_border{
    margin-left: -49%;
    width: 428%;
  }
  .third_card .order_description{
    margin-left: 140%;
    margin-top: -130%;
    width: 300%;
  }
  .third_card .order_description span{
    margin-left: 4%;
  }
  .orders .deliver{
    padding-top: 30%;
    margin-top: -10%;
    
   }
   .fourth_card .deliver h6{
    padding-top: 25%;
    margin-top: -25%;
    padding-bottom: 4%;
  }
  .fourth_card .deliver span{
    margin-left: 72%;
  }
  .fourth_card .deliver .total_price span{
    margin-left: 60%;
  }
  .fourth_card .total{
    margin-top: 10%;
  }
  .fourth_card .total h6{
    margin-top: -5%;
  }
  .fourth_card .total span{
    margin-left: 60%;
  }
  .fourth_card .total button{
    width: 90%;
    background-color: #F4AD33;
   padding-top: 2%;
   padding-bottom: 2%;
    color: #fff;
    margin-left: 5%;
    margin-top: 7%;
    margin-bottom: 5%;
  }
  .content .product_list{
    margin-top: 10%;
    margin-left: -5%;
  }
  
  
   .product_list {
     margin-top: 200px;
     margin-left: 30px;
   }
  
  
  
   .product_list title {
     margin-left: -100px;
   }
  
   .product_list .stars {
     margin-left: -21px;
   }
  
   .product_list .stars .fa-solid {
     color: #F4AD33;
     margin-left: 20px;
     margin-top: 20px;
   }
  
   .product_list p {
     margin-top: -20px;
   }
  
   .product_list .bag .fa-solid {
     margin-top: 20px;
     margin-left: 180px;
   }
  
  
   
  .info .content .steps{
    width: 50%;
    margin-left: 22%;
    margin-top: 5%;
  }
  .info .content .address{
    margin-top: -2%;
   
  }
  
  .info .orders {
    margin-left: 55%;
    margin-top: -36%;
    
  }
  
  .info .orders header {
    margin-top: -7%;
    margin-left: -6%;
    width: 110%;
    height: 20px;
    background-color: #fff;
  }
  
  .info .orders .bottom_border {
    border-bottom: 1px solid #000;
    background-color: #fff;
    padding-bottom: 2%;
    padding-top: -22px;
    margin-left: -6%;
    width: 112%;
  }
  .info .orders .total h6{
    padding-top: 0%;
   padding-bottom: 20%;
  
  }
  .info .third_card .bottom_border{
    width: 450%;
    margin-left: -50%;
  }
  .info  .info_input button{
    margin-left: 15%;
    width: 30%;
    padding-top: 0.5%;
    margin-top: 4%;
    padding-bottom: 0.5%;
    background-color: #F4AD33;
    color: #fff;
    font-size: 150%;
  }
  .info .footer{
    background-color: #000;
      margin-top: 20%;
      padding-top: 10%;
      padding-bottom: 5%;
  }
  
  
  .transport .content .steps{
   width: 50%;
   margin-left: 22%;
   margin-top: 5%;
   }
  .transport .content .product_card header{
    margin-top: -15%;
    margin-left: -8%;
    padding-bottom: 2%;
    width: 116%;
  }
  .transport .content .product_card{
    margin-left: 60%;
    margin-top: 3%;
  }
  .transport .product_card .delete_btn{
    margin-top: -12%;
  }
  .transport .product_card .bottom_border{
    margin-left: -8%;
    width: 116%;
  }
  .transport .product_card h6{
    margin-top: 10%;
  }
  .transport .buttons{
    margin-left: 10%;
    margin-top: 20%;
   
    
  }
  .transport .content button{
    width: 25%;
    margin-top: 3%;
    margin-left: 10%;
   padding-top: 1%;
    color: #fff;
    font-size: 150%;
    
    
    
  
  }
  .transport .orders{
   top: 0;
   left: 0;
   margin-top: -320px;
   margin-right: 120px;
  
  
  }
  .transport .orders .deliver .total_price{
    padding-top: 120px;
    width: 100%;
  }
  .transport .content .orders .title h6{
    position: absolute;
    top: 0;
    right: 0;
   margin-top: 30px;
   margin-right: 40px;
  }
  
  /* Cart Pages */
  
  
  .cart_page .orders .deliver .total_price {
    padding-top: 120px;
    width: 100%;
  }
  
  .cart_page  .orders .title h6 {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 30px;
    margin-right: 40px;
  }
  
  .transport .footer {
    background-color: #000;
    margin-top: 20%;
    padding-top: 10%;
    padding-bottom: 5%;
  }
  .payment .content{
   background-color: #FFFFFF;
   margin-top: -5%;
   padding-bottom: 20%;
  }
  
  .payment .content .steps{
    width: 50%;
      margin-left: 22%;
      margin-top: 5%;
      padding-top: 5%;
  }
  .payment .content h5{
    margin-left: 13%;
  }
  .payment .content .body{
    margin-top: 7%;
  }
  .payment .content .basic_info{
    margin-left: 6%;
    margin-top: -4%;
  }
  .payment .content button{
    margin-left: 13%;
    margin-top: 3%;
    width: 20%;
    color: #fff;
    background-color: #F4AD33;
    font-weight: bold;
    font-size: large;
  }
  .payment .content .orders .bottom_border{
    margin-top: 8%;
  }
  .payment .footer{
    background-color: #000;
      margin-top: 0%;
      padding-top: 10%;
      padding-bottom: 5%;
  }
  .success{
    margin-left:20%;
    padding-left:21%;
    margin-top: 10%;
    padding-top: 5%;
    background-color: #fff;
    height: 60%;
    width: 60%;
    padding-bottom: 15%;
    z-index: 1;
    position: absolute;
   }
  .success .container-fluid{
    z-index: 1;
   
  }
  
  
  .success .logo{
    margin-left: 2%;
    z-index: 1;
   
  }
  .success button{
    margin-left: 8%;
    width: 30%;
    margin-top: 2%;
    font-weight: bold;
    color: #fff;
    background-color: #000;
   
  }
  .order_managment .second_card .items{
    display: flex;
    margin-left: 10%;
  
  
  }
  .order_managment .second_card .links button{
    padding-left: 26%;
   margin-top: -2%;
   color: #000;
   font-weight: bold;
   margin-left: -20%;
  }
  
  .order_managment .second_card .items h6{
  width: 100%;
  }
  
  .order_managment .footer{
     background-color: #000;
  margin-top: 10%;
  padding-top: 10%;
  padding-bottom: 5%;
  }
  
  .home_page .easy{
    z-index: 1;
  }
  
  /* admin dashboard */
  
  .admin_table {
    max-width: 69%;
  }
  
  /* .admin_table_icons {
    margin-top: -40px;
  } */
  
  
  .table_column {
    margin-top: 20px;
  }
  
  .admin_table .bottom_border {
    border-bottom: 1px solid #DFE0EB;
    margin-left: -25px;
    margin-top: 20px;
    /* padding-bottom: 2%;
      padding-top: -22px; */
    width: 105.5%;
  }
  
  .table_element {
    margin-top: 25px;
    height: 50px;
  }
  
  /* .outlines_ {
    margin-top: 1%;
    left: -50px;
    letter-spacing: 5rem;
  } */
  
  .admin_table .topHeader{
    margin-top: 10px;
  }
  
  .admin_table {
    max-width: 69%;
  }
  
  .admin_table_icons {
    margin-top: -40px;
  }
  
  .table_column {
    margin-top: 20px;
  }
  
  .admin_table .bottom_border {
    border-bottom: 1px solid #DFE0EB;
    margin-left: -25px;
    /* padding-bottom: 2%;
      padding-top: -22px; */
    width: 105.5%;
  }
  
  .table_element {
    margin-top: 25px;
    height: 50px;
  }
  
  .outlines {
    margin-top: -35px;
  }
  
  
  
  .order_managment .second_card .items {
    display: flex;
    margin-left: 10%;
  
  
  }
  
  .order_managment .second_card .links button {
    padding-left: 26%;
    margin-top: -2%;
    color: #000;
    font-weight: bold;
    margin-left: -20%;
  }
  
  .order_managment .second_card .items h6 {
    width: 100%;
  }
  
  div .links Button {
    font-size: 15;
    font-weight: bold;
    color: #000
  }
  
  
  /* .order_managment .footer{
     background-color: #000;
  margin-top: 10%;
  padding-top: 10%;
  padding-bottom: 5%;
  } */
  
  
  /* admin header  */
  
  .admin_logo .img {
    width: 60px;
    height: 60px;
    margin-left: 80px;
    margin-top: -90px;
  }
  
  .admin_logo a {
    margin-left: 140px;
    color: #fff;
    font-size: 29px;
  }
  
  .sergela_link {
    margin-top: -80px;
  }
  
  .admin_logo a span {
    color: #fff;
    font-size: 29px;
    font-weight: bold;
  }
  
  .admin_menu {
    height: 70px;
  }
  
  .ant-menu>.ant-menu-item span .span_header {
    color: #FAAD14;
    font-size: 16px;
  }
  
  .ant-menu>.ant-menu-item span h6 {
    padding-left: 50%;
    margin-top: -10px;
    color: white;
    font-size: 16px;
  }
  
  .ant-menu>.ant-menu-item .abe_img {
    margin-top: 12px;
    width: 50px;
    /* height: 50px; */
    border-radius: 50px;
  }
  
  .gear_icons {
    margin-top: -45px;
    margin-left:100%;
  
  }
  
  /* admin navigation */
  
  .admin_nav .ant-menu>.ant-menu-item {
    color: #262626;
    font-size: 12px;
    left:2px;
  }
  
  .admin_nav .ant-menu>.ant-menu-item-selected {
    color: #FAAD14 !important;
    border-bottom: 3px solid #FAAD14;
    /* background-color: red; */
  }
  
  .admin_nav .ant-menu>.ant-menu-item:hover {
    color: #FAAD14 !important;
  }
  
  .home {
    color: #262626;
    font-size: 20px;
  }
  
  .admin_nav .ant-menu-horizontal>.ant-menu-item::after {
    border-bottom: none !important;
  }
  
  /* admin rbac style */
  .rbac_main .acc_imgs {
    margin-top: -50px;
    margin-left: 155px;
  }
  .rbac_filter .acc_imgs{
    margin-top: -50px;
    margin-left: 155px;
  }
  
  .more_outline_admin_table {
    margin-top: -27px;
    margin-left: 1020px;
  }
  
  /* admin dashboard */
  .gutter-box{
    padding: 8px 0;
  }
  .small_size{
    display:none;
  }
  @media only screen and (max-width: 500px) {
    .login {
       width: 70%;
     }
    }
  
  @media only screen and (max-width:992px){
    .admin_nav{
      display: none;
    }
    .small_size{
      display: block;
    }
    .ant-menu>.ant-menu-item {
      color: #262626;
      font-size: 12px;
      left:50%;
    }
  
    .admin_header .ant-menu>.ant-menu-item{
      left:60%;
    }
  
    .small_size .btn{
      margin-top: -20%;
      left:-50%;
    }
  }
  .delete_modal .ant-modal-header{
    background-color: #FAAD14;
  
  }
  .delete_modal .ant-modal-title{
    text-align: center;
    font-size: x-large;
  }
  .delete_modal .ant-modal-content p{
    margin-left: 35%;
    
  }
  .delete_modal .ant-modal-btn{
    background-color: red;
  }
  .search-input .ant-input-search{
    height: 50px;
  }
  .search-input .ant-input-search-button{
    margin-left: 10px;
    height:30px;
  }
  
  
  
  
  /* register page css */
  .register_header{
      text-align: center;
  }
  
  .home_header .header{
   
  }
  .home_header .logo img{
    margin-left: -16%;
    margin-top: 1%;
  }
  .home_header .user_input {
    width: 485;
    margin-left: 300px;
    margin-top: -45px;
  }
  .home_header .logo a{
    margin-top: 2%;
  }
  .home_header .user_input .search{
      margin-left: 140px;
      padding-bottom: -3%;
      margin-top: 0.1%;
    
    }
  
  .home_header .user_input .search:hover{
    border-color: #fff;
  }
  
  .home_header .user_input .search ::placeholder{
    text-align: center;
    margin-left: 60%;
  
   
  }
  .home_header .user_input .dropdown{
    margin-left: 0.1%;
    background-color: #fff;
    width: 40%;
    padding-top: 2.2%;
    padding-bottom: 2.2%;
    margin-top: -11.5%;
   
  }
  .home_header .user_input .btn Button{
    margin-left: 150%;
    width: 150%;
    margin-top: 27%;
    background-color: #F4AD33;
   }
  .header{
    background-color: #1D1D1D;
   }
   .home_header .icons{
    margin-left: 79%;
    margin-top: -2%;
   }
  
   .home_header .btn button{
    width: 100%;
    margin-left: 310%;
    margin-top: 20%;
   }
  .new_class{
    background-color: #fff;
    margin-left: 3%;
   width: 20%;
  }
  
   /* Side nav */
   .last_side_nav{
    background-color: #fff;
    margin-left: 1%;
    margin-top: 3%;
    height: "100%";
   }
   .last_side_nav ul li{
    padding-top: 15%;
    list-style: none;
    
  }
  
  .last_side_nav ul li a{
   margin-left: 10%;
  }
  .last_side_nav ul li .fa-solid{
    color: #796E6E;
    font-size: 130%;
  }
  .last_side_nav ul li a{
    color: #796E6E;
  }
  .last_home .body{
    background-color: #fff;
  
  }
  .last_home .content{
    background-color: #fff;
    padding-top: 10%;
    margin-top: -100px;
    height: 400px;
    margin-left: 5%;
  }
  .last_home .content p{
    width: 50%;
  }
  .last_home .content h3{
    font-family: Ethiopic Sadiss;
    
  }
  .last_home .content button{
    background-color: #F4AD33;
    color: #fff;
    padding-top: 1%;
    height: 40px;
  }
  .last_home .content span{
    font-weight: bold;
  }
  .last_home .content .circular{
    background-color: #FCE2AD;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    margin-left: 55%;
    margin-top: -25%;
  }
  .last_home .content .circular img{
    margin-left: -20%;
    margin-top: 15%;
  }
  .last_home .right_side{
    background-color: #fff;
    height: 560px;
    margin-left: 81%;
    width:231px;
    margin-top: 0%;
  }
  .last_home .right_side img{
    margin-top: 10%;
    margin-left: 35%;
    
  }
  .last_home .right_side h5{
    margin-left: 12%;
    margin-top: 10%;
  }
  .last_home .right_side .btn{
    margin-left: 20%;
    margin-top: 10%;
  }
  .last_home .right_side .rectangle{
    margin-left: -47%;
    margin-top: -5%;
  }
  .last_home .right_side .rectangle img{
   margin-top: 5%;
   
  }
  .last_home .right_side .rectangle h6 {
    margin-top: -25%;
    width: 60%;
    margin-left: 39%;
    font-size: 13px;
    color: #fff;
  }
  .last_home .right_side .rectangle button {
    margin-left: 40%;
    margin-top: 1%;
    background-color: #000;
    border-radius: 10%;
    color: #fff;
    width: 37%;
  }
  .last_home .services{
    margin-top: 3%;
    
  }
  .selected_items{
    margin-left: 4.9%;
    margin-top: 0%;
    width: 92%;
    height: 7%;
    background-color: #fff;
  }
  .selected_items p{
    margin-left: 23%;
    margin-top: 8%;
   }
   .last_home .banner{
    width: 100%;
   }
  
   .last_home .banner img{
    margin-left: 5%;
    width: 92%;
    margin-top: 2%;
   
   }
   
   .last_home .banner p{
    color: #fff;
    margin-top: -17%;
    font-weight: bold;
    margin-left: 72%;
    width: 20%;
    font-size: 110%;
   }
   .high_rating h6{
    padding-top: 30px;
   
  }
  .last_home .package{
    background-color: #F08F33;
    height: 9%;
    margin-top: 5%;
    width: 92%;
    border-radius: 10px;
  }
  .last_home .package .content h3{
    left: 0;
    margin-left: 8%;
    position: absolute;
     margin-top: -3%;
     font-weight: bold;
     color: #fff;
  }
  .last_home .package .content p{
    left: 0;
      margin-left: 8%;
      position: absolute;
    color: #fff;
    width: 15%;
  }
  .last_home .package img{
    padding-bottom: 4%;
  }
  .last_home .discounts{
    background-color: #FADFA9;
      height: 12%;
      margin-top: 2%;
      width: 92%;
      margin-left: 5%;
      border-radius: 10px;
  }
  .last_home .discounts h2{
    text-align: center;
    margin-left: -3%;
    padding-top: 3%;
  }
  .last_home .discounts img{
    margin-top: 2%;
    height: 90%;
    margin-left: 15%;
  }
  .last_home .for_womens{
    background-color: #F6BA33;
    margin-top: 3%;
    height: 9%;
    width: 92%;
    margin-left: 5%;
    border-radius: 10px;
  }
  .last_home .for_womens h2{
    margin-left: 30%;
    margin-top: -15%;
    font-size: 60px;
    text-align: center;
    width: 60%;
    color: #fff;
  }
  .last_home .for_womens img {
    margin-left: 10%;
    margin-top: -2%;
  }
  .home_footer{
    background-color: #000;
    height: 350px;
  }
  .home_footer h1{
    color: #fff;
    padding-top: 20%;
    margin-left: 20%;
    width: 100%;
   
  }
  .home_footer h3 {
    color: #fff;
  }
  .home_footer h4 {
    color: #fff;
    font-size: 15px;
    margin-left: 20%;
    padding-top: 10%;
    width: 100%;
  
  }
  .home_footer h5 {
    color: #fff;
  }
  .home_footer button{
    margin-left: 94%;
  }
  .admin .header_corner .fa {
    display: none;
  }
  
  
  .infomodal .ant-modal-footer>.ant-btn>.okbtn{
    width:20%;
    margin-right: 40%;
    background-color:#FAAD14;
    color:#000;
    border-color:#FAAD14;
  }
  
  .infomodal{
    z-index: 10;
    opacity: 1;
  }
  
  .supplierEditModal .ant-modal-btn{
    margin-left: auto;
    margin-right: auto;
  }
  
  
  
  
  
  
  
  /* css online status */
  
  
  .bubble {
    display: block; 
    position: absolute;
    cursor: pointer; 
  }
  
  .bubble:hover:after {
    background-color: green
  }
  
  .bubble:after {
    content: "";
    background-color: green;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    display: block;
    top: 1px;
    left: 1px;
  }
  
  .bubble .bubble-outer-dot {
    margin: 1px;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: green;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-animation: bubble-pulse 1.5s linear infinite;
    -moz-animation: bubble-pulse 1.5s linear infinite;
    -o-animation: bubble-pulse 1.5s linear infinite;
    animation: bubble-pulse 1.5s linear infinite
  }
  
  .bubble .bubble-inner-dot {
   
    display: block;
    text-align: center;
    opacity: 1;
    background-color: green;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-animation: bubble-pulse 1.5s linear infinite;
    -moz-animation: bubble-pulse 1.5s linear infinite;
    -o-animation: bubble-pulse 1.5s linear infinite;
    animation: bubble-pulse 1.5s linear infinite
  }
  
  .bubble .bubble-inner-dot:after {
    content: "";
    display: block;
    text-align: center;
    opacity: 1;
    background-color: green;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-animation: bubble-pulse 1.5s linear infinite;
    -moz-animation: bubble-pulse 1.5s linear infinite;
    -o-animation: bubble-pulse 1.5s linear infinite;
    animation: bubble-pulse 1.5s linear infinite
  }
  
  @-webkit-keyframes bubble-pulse {
    0% {
      transform: scale(1);
      opacity: .75
    }
    25% {
      transform: scale(1);
      opacity: .75
    }
    100% {
      transform: scale(2.5);
      opacity: 0
    }
  }
  
  @keyframes bubble-pulse {
    0% {
      transform: scale(1);
      opacity: .75
    }
    25% {
      transform: scale(1);
      opacity: .75
    }
    100% {
      transform: scale(2.5);
      opacity: 0
    }
  }
  
  @-moz-keyframes bubble-pulse {
    0% {
      transform: scale(1);
      opacity: .75
    }
    25% {
      transform: scale(1);
      opacity: .75
    }
    100% {
      transform: scale(2.5);
      opacity: 0
    }
  }
  
  @-o-keyframes bubble-pulse {
    0% {
      transform: scale(1);
      opacity: .75
    }
    25% {
      transform: scale(1);
      opacity: .75
    }
    100% {
      transform: scale(2.5);
      opacity: 0
    }
  }
  
  
  /*Center-div (Not part of the symbol)*/
  
  #center-div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-left: -90;
    width: 14px;
    height: 14px;
  }
  
  
  
  
  
  
  
  
  /* customer side css */
  .home_header .header{
    width: 100%;
   
  }
  .home_header .logo img{
    margin-left: -210px;
    margin-top: 15px;
  }
  .home_header .user_input {
    width: 30%;
    margin-left: 200px;
    margin-top: -45px;
  }
  .home_header .logo a{
    margin-top: 2%;
  }
  .home_header .user_input .search{
      margin-left:39%;
      padding-bottom: 1%;
  
     margin-top: 3%;
    
    }
  
  .home_header .user_input .search:hover{
    border-color: #fff;
  }
  
  .home_header .user_input .search ::placeholder{
    text-align: center;
    margin-left: 60%;
  
   
  }
  .home_header .user_input .dropdown{
    margin-left: -1%;
    background-color: #fff;
    width: 40%;
    padding-top: 2%;
    padding-bottom: 2.2%;
    margin-top: -10%;
   
  }
  .home_header .user_input .btn Button{
    margin-left: 150%;
    width: 150%;
    margin-top: 27%;
    background-color: #F4AD33;
   }
  .header{
    background-color: #1D1D1D;
   }
   .home_header .icons{
    margin-left: 79%;
    margin-top: -2%;
   }
  
   
   .home_header .btn button{
    width: 100%;
    margin-left: 310%;
    margin-top: 20%;
   }
   .last_home{
    display: inlne;
    width: 100%;
   }
   /* Side nav */
   .last_home .new_nav {
     display: none;
     background-color: #FAFAFA;
     width: 100%;
     margin-left: 0%;
     margin-top: 2%;
     height: 700px;
   }
   .last_home .new_nav ul li {
     padding-top: 15%;
     list-style: none;
    }
   .last_home .new_nav ul li a {
     margin-left: 20px;
   }
  
   .last_home .new_nav ul li .fa-solid {
     color: #796E6E;
     font-size: 130%;
   }
  
   .last_home .new_nav ul li a {
     color: #796E6E;
   }
   .last_home .only_icon {
     display: none;
     background-color: #fff;
     width: 15%;
     margin-top: 10px;
     padding-top: 60px;
     height: 700px;
   }
  
   .last_home .only_icon ul li {
     padding-top: 15%;
     list-style: none;
  
   }
   .last_home .only_icon ul li .fa-solid {
     color: #796E6E;
     font-size: 150%;
     margin-left: -20px;
   }
  .last_home .some_btn{
     width: 98%;
     padding-top: 1%;
     padding-bottom: 1%;
     height: 1.5%;
     margin-left:20px;
     margin-top:50px;
     background-color: #FDEFD0;
     text-align: center;
  }
  .last_home .some_btn Button{
   background: none;
   border: none;
   font-weight: bold;
   font-size: 20px;
   cursor: pointer;
  }
  
  
  
   .last_home .last_side_nav{
   
    display: block;
    width: 21%;
   margin-top: 10px;
    height: 670px;
   }
   .last_home .last_side_nav ul li{
    padding-top: 15%;
    list-style: none;
    
  }
  
  .last_side_nav ul li a{
   margin-left: 10%;
  }
  .last_side_nav ul li .fa-solid{
    color: #796E6E;
    font-size: 130%;
  }
  .last_side_nav ul li a{
    color: #796E6E;
  }
  .last_home .body{
    background-color: #fff;
    display: block;
    height: 15%;
    width: 55%;
    margin-left:23%;
  }
  .last_home .body .content{
    margin-top: 1px;
    height: 450px;
  }
  /* .last_home .content{
   
   padding-top: 10%;
    margin-left: 5%;
  } */
  .last_home .content p{
    width: 50%;
  }
  .last_home .content h3{
    font-family: Ethiopic Sadiss;
    
  }
  .last_home .content button{
    background-color: #F4AD33;
    color: #fff;
    padding-top: 1%;
    height: 40px;
  }
  .last_home .content span{
    font-weight: bold;
  }
  .last_home .content .circular{
    background-color: #FCE2AD;
    height: 270px;
    width: 270px;
    border-radius: 50%;
    margin-left: 55%;
    margin-top: -200px;
  }
  .last_home .content .circular img{
    margin-left: -20%;
    margin-top: 15%;
    width: 350px;
  }
  .last_home .right_side{
    background-color: #fff;
    height: 670px;
    margin-left: 80%;
    width:19%;
    margin-top: -33.2%;
    /* margin-top: -600px; */
  }
  .last_home .right_side h5{
    text-align: center;
  }
  
  .last_home .right_side Button{
     display: inline;
     color: #F08F33;
     width: 43%;
    white-space: normal;
    margin-left: 10px;
    font-weight: bold;
    height: 7%;
    }
   .last_home .right_side .enter_btn{
    background-color: #F08F33;
     color: #fff;
      white-space: normal;
     
     width: 43%;
   }
   .last_home .right_side .rectangle{
  
   
    }
   .last_home .right_side .rectangle img{
    
    width: 62%;
    padding-top: 20px;
    margin-top: 20px;
    height: 490px;
   }
  .last_home .right_side img{
    margin-top: 10%;
    margin-left: 35%;
    
  }
  .last_home .right_side h5{
    margin-left: 12%;
    margin-top: 10%;
  }
  .last_home .right_side .btn{
    margin-left: 20%;
    margin-top: 10%;
  }
  .last_home .right_side .rectangle{
    margin-left: -47%;
    margin-top: -5%;
  }
  .last_home .right_side .rectangle img{
   margin-top: 5%;
  }
  .last_home .right_side .rectangle h6 {
    margin-top: -120px;
    width: 60%;
    margin-left: 39%;
    font-size: 13px;
    color: #fff;
  }
  .last_home .right_side .rectangle button {
    margin-left: 40%;
    margin-top: 10px;
    background-color: #000;
    border-radius: 10%;
    color: #fff;
    white-space: normal;
    display: block;
    width: 45%;
  }
  .last_home .bars{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 210px;
    margin-left: 10px;
    display: none;
  }
  .last_home .bars .fa-solid{
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    
  }
  .last_home .new_nav{
    display: none;
  }
  
   
  /* Last Home Page */
  .last_home{
    background-color: #FAFAFA;
  }
  
  .last_home .services{
    margin-top: -175px;
    margin-left: 5px;
   
  }
  .last_home .services .card{
  background-color: #F9F9F9;
    height: 160px;
    width: 54%;
    margin-left: 23%;
    margin-top: -80px;
    display: inline;
    border-color: #F9F9F9;;
  }
  .last_home .services .card .cards img{
   
  }
  .last_home .services  .cards{
    width: 10%;
    flex: 1;
    padding: 16px;
    height:150px;
    margin-left: 1%;
    background-color: #fff;
    display: inline-block;
  }
  .last_home .services .cards img{
   margin-left:35%;
   margin-top: 0px;
  }
  .last_home .services .cards h6{
    margin-top: 20px;
    margin-left: 4%;
    text-align: center;
    font-size: 90%;
    font-weight: bold;
  }
  .last_home .services .cards p{
    margin-left:-3%;
    width: 100%;
    text-align: center;
    font-size: 90%;
  }
  
  .selected_items{
    margin-left: 4.9%;
    margin-top: 0%;
    width: 92%;
    height: 10%;
    background-color: #fff;
  }
  .selected_items p{
    margin-left: 23%;
    margin-top: 8%;
   }
   .last_home .banner img{
    margin-left: 1.5%;
    width: 98%;
    margin-top: 2%;
   
   }
   .last_home .banner p{
    color: #fff;
    margin-top: -17%;
    font-weight: bold;
    margin-left: 72%;
    width: 20%;
    font-size: 110%;
   }
   .high_rating h6{
    margin-top: 16%;
    margin-left: 5%;
  }
  .last_home .package{
    background-color: #F08F33;
    height: 9%;
    margin-top: 5%;
    width: 97%;
    border-radius: 20px;
    margin-left: 2.3%;
    border-radius: 20px;
  }
  .last_home .package .content h3{
     margin-top: -3%;
     font-weight: bold;
     color: #fff;
  }
  .last_home .package .content p{
    color: #fff;
    width: 15%;
  }
  .last_home .package img{
    padding-bottom: 4%;
  }
  .last_home .discounts{
    background-color: #FADFA9;
      height: 12%;
      margin-top: 2%;
      width: 92%;
      margin-left: 5%;
      border-radius: 10px;
  }
  .last_home .discounts h2{
    text-align: center;
    margin-left: -3%;
    padding-top: 3%;
  }
  .last_home .discounts .discount_img{
    width: 30%;
    margin-left: 1%;
  }
  .last_home .discounts img{
    margin-top: 20px;
    height: 90%;
    margin-left: 15%;
    width: 100%;
  }
  .last_home .for_womens{
    background-color: #F6BA33;
    margin-top: 3%;
    height: 9%;
    width: 92%;
    margin-left: 5%;
    border-radius: 10px;
  }
  .last_home .for_womens h2{
    margin-left: 400px;
    margin-top: -200px;
    font-size: 60px;
    text-align: center;
    width: 60%;
    color: #fff;
  }
  .last_home .for_womens img {
    margin-left: 10%;
    margin-top: -2%;
  }
  .home_footer{
    background-color: #000;
    height:350px;
    width: 100%;
  }
  .home_footer .part_1{
    width: 100%;
  
  }
  .home_footer .part_1 Button{
    margin-left: 331px;
    width: 30%;
    height: 35px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: -3px;
    font-size: 100%;
    padding-right: 10%;
  }
  .home_footer .part_1 input{
    margin-left: 70px;
    width:21%;
    height: 35px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .home_footer .part_1  ::placeholder{
    color: #000;
    position: absolute;
    left: 0;
    margin-left: 30px;
    margin-top: 4px;
  
  }
  .home_footer .footers {
    display: flex;
   margin-top: -200px;
   padding-top: 280px;
   width: 65%;
   height: 230px;
   margin-left: 35%;
  }
  .home_footer .footers .part_2{
  display: inline-block;
   margin-left: 3%;
   height: 200px;
   width: 21%;
  margin-top: -280px;
  
  }
  .home_footer h1{
    color: #fff;
    padding-top: 20%;
    margin-left: 70px;
   
  }
  .home_footer h3 {
    color: #fff;
  }
  .home_footer h4 {
    color: #fff;
    font-size: 15px;
    margin-left: 70px;
    padding-top: 10%;
    width: 100%;
  
  }
  .home_footer h5 {
    color: #fff;
    
  }
  .home_footer button{
    margin-left: 94%;
  }
  .admin .header_corner .fa {
    display: none;
  }
  
  
  /* For Mobile  */
  @media only screen and (max-width: 480px) {
    .admin .header_corner .fa {
        display: block;
      }
      .admin Header{
        width: 100%;
      }
    
      .admin .header_corner .menu .fa{
        margin-top: -70px;
        margin-left: 70vw;
      }
      .admin .logo img {
       margin-top: -10px;
       margin-left: -120px;
          }
      .admin .logo a {
            margin-left: -20px;
            margin-top: 40px;
          }
          .home_header .header{
            width: 100%;
          }
       .home_header .logo img {
          margin-left: -90px;
          margin-top: 8px;
          height: 50px;
      
        }
      .home_header .logo a {
         margin-left: 10px;
          margin-top: 50px;
          font-size: 20px;
        }
         .home_header .dropdown{
          margin-left: 10px;
        }
        .home_header .header .menus{
          margin-left: -900px;
        }
        .home_header .user_input .dropdown{
         width: 130px;
          margin-left: -140px;
          margin-top: -40px;
          height: 40px;
        }
         .home_header .user_input .search{
          width: 100%;
          margin-left: -10px;
          margin-top: 20px;
         
        }
        .home_header .icons{
          margin-top: -100px;
          margin-left: 70%;
        }
        .home_header .menu_btn{
          margin-top: -50px;
        }
  
          
        .home_header .ant-menu>.ant-menu-item{
          color: red;
        
        } 
       
        /*
        .home_header .user_input .dropdown{
          width: 90%;
          margin-left: -125%;
          margin-top: -40px;
          height: 40px;
        }
        .home_header .user_input .search{
          margin-left: -34%;
          margin-top: 17%;
          width: 90%;
  } 
        .home_header .header .menus{
        margin-left: -400px;
        
        }
        .home_header .icons{
          margin-top: -6%;
        } */
  
  }
  .home_header .ant-menu>.ant-menu-item {
   
    color: red;
  }
   .home_header .menu_btn .fa-solid{
     color: #fff;
   }
  @media only screen and (min-width: 481px) {
  .login{
    margin-top: 25%;
    width: 100%;
  }
  .login_header{
    margin-left: 45%;
    margin-top: -10%;
  }
  /* Admin Page */
  .admin .header_corner .fa{
    display: block;
    
  }
   .admin .header_corner .menu .fa{
    margin-top: -80px;
    margin-left: 100%;
   }
   .admin .logo img {
     margin-top: -10px;
     margin-left: -120px;
   }
  
   .admin .logo a {
     margin-left: -20px;
     margin-top: 10px;
   }
          .home_header .logo img {
            margin-left: -90px;
            margin-top: 8px;
            height: 50px;
      
          }
      
          .home_header .logo a {
            margin-left: 10px;
            margin-top: 50px;
            font-size: 20px;
          }
  
         .home_header .dropdown{
          margin-left: 10px;
        }
      
  
  /* .home_header .user_input .dropdown{
    margin-left: -170px;
    margin-top: -40px;
    height: 40px;
    width: 100%;
  }
  .home_header .user_input .search{
  margin-left: -30%;
  margin-top: 20px;
  width: 100%;
  }
  .home_header .header {
    width: 100%;
  }
   */
  
  
  
  }
  
  
  /* @media only screen and (max-width: 576px) {
    .admin .header_corner .fa {
        display: block;
      }
    .admin .header_corner .menu {
        margin-top: -100px;
        margin-left: 90%;
      }
  }
  @media only screen and (max-width: 992px) {
    .admin .header_corner .fa {
        display: block;
      }
    
      .admin .header_corner .menu {
        margin-top: -100px;
        margin-left: 90%;
      }
  }
  @media only screen and (min-width: 576px) {
  .login {
    width: 100%;
    }
    .login_header {
     
   }
      .admin .header_corner .fa {
        display: block;
      }
    
      .admin .header_corner .menu {
        margin-top: -24%;
        margin-left: 90%;
      }
   
  } */
  .add_item_card  {
   margin-left: 30px;
  height: 126px;
  margin-top: -200%;
  padding-left: 150px;
    position: relative;
   
   
  }
  .add_item_card2 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  
    margin-left: 30px;
    height: 126px;
    margin-top: -200%;
    padding-left: 150px;
    position: relative;
    border: 1px solid #fff;
  
  }
  
  .add_item_card2:hover {
    background-color: #FAFAFA;
  }
  
  .item_pics  .col .new_items_added{
  display: flex;
  margin-top: -380%;
  margin-left: 120px;
  position: static;
  }
  
  .item_pics .new_items {
    display: flex;
    margin-top: 230%;
    margin-left: -30px;
    position: static;
  }
   .new_item_added2{
    
    height: 170px;
    width: 500px;
    margin-left: -26%;
  }
  .last_header .header{
    width: 100%;
    background-color: #AE7F2D;
    height: 180px;
  }
   nav{
    display: flex;
    width: 100%;
    text-align: center;
    position: relative;
    justify-content: space-between;
  
  }
  /* .nav .search_input{
    display: flex;
  } */
  
  nav .icon{
  font-size: 40px;
  font-family:'Times New Roman', Times, serif;
  padding-top: 2%;
  margin-top: -3%;
  /* background-color: #F08F33; */
  width: 40%;
  
  }
  nav .icon a{
  color:#fff;
  }
  nav .icon span{
    font-weight: bold;
  }
  nav .icon img{
    width: 25%;
    right: 0;
    margin-top: -2%;
  }
  nav .drop {
    margin-top: 0px;
    padding-top: 50px;
  
    margin-right: 36%;
    /* background-color: #3071b9; */
  
  }
  
  nav .dropdown {
    border: 1px solid #fff;
    color: #000;
    background-color: #fff;
    justify-content: center;
    height: 44px;
    margin-left: -50px;
    padding-left: 2%;
    position: absolute;
    padding-top: 10px;
    margin-top: 0px;
  
   
  
  }
   .last_home .close {
     display: none;
   }
   .last_home .close .fa-solid{
    font-size: 30px;
    margin-left: 80%;
    margin-top: 10%;
    cursor: pointer;
   }
  
  nav .search_input{
   
   margin-right: -15%;
    height: 160px;
    margin-top: 40px;
    padding-top: 50px;
   
    display: flex;
  }
  nav .search_input .profile{
    margin-left: 10%;
  }
  nav .search_input .logout{
    margin-left: 10%;
  }
  nav .search_input input {
    padding-left: 2%;
    width: 50%;
    border: none;
    justify-content: center;
    width: 40%;
    height: 44px;
    margin-right : 0%;
  }
  nav .search_input Button{
    height: 44px;
    padding-bottom: 4px;
    padding-top: 5px;
    background-color: #F08F33;
    color: #fff;
  }
  .last_header .header .new_class {
   
  }
  .last_home .selected_btn Button {
    width: 1325px;
  }
  @media only screen and (max-width: 480px){
  /* .last_header .header .new_class {
      display: block;
      margin-left: 320px;
      background-color: #fff;
      width: 32%;
      margin-top: 24px;
      height: 120px;
      border-radius: 10px;
    }
   .last_header .header .new_class ul {
     opacity: 1;
   }
   .last_header .header .new_class ul li a {
     color: #000;
   }
   .last_header .header .new_class ul li a:hover {
     text-decoration: underline;
     color: #F08F33;
   }
   .last_header .header .new_class ul li {
     list-style: none;
     color: #000;
     opacity: 10;
     font-weight: bold;
     font-size: 15px;
     margin-left: 10px;
     padding-top: 10px;
   }
   .last_side_nav{
    width: 20%;
    margin-left: -5%;
    height: 17%;
   }
  .last_side_nav ul li a{
   width: 100%;
  }
  .last_home .body .content{
    margin-top: -67%;
  }
  .last_home .body{
    margin-left:30%;
    margin-top: -595px;
    width: 70%;
  }
  .last_home .body .content p{
    width: 100%;
  }
  .last_home .content .circular{
    height: 200px;
    width: 200px;
    margin-left: 12%;
    margin-top: -5%;
  }
  .last_home .content .circular img {
    width: 160%;
    margin-right: -20%;
    height: 80%;
  padding-top: 10%;
   
  }
  .last_home .right_side{
    margin-left: 30%;
    margin-top: 0%;
  }
  .last_home .services{
    margin-top: 50%;
    margin-left: -65%;
  }
  .last_home .services .card1{
  }
  .last_home .services .card2 {
    padding-left: 5%;
  }
  .last_home .services .card3 {
    padding-left: 10%;
  }
  .last_home .services .card4{
    margin-top: 20%;
   padding-right: 50%;
   padding-left: 20%;
  }
  .last_home .services .card5 {
    padding-right: 50%;
    margin-top: -1.3%;
    padding-left: -10%;
  }
  .last_home .selected_btn{
  margin-left: -12%;
  }
  .last_home .selected_btn Button{
  width: 87%;
  } */
  /* .last_home .selected_items .card1{
    margin-left: -4%;
  }
  .last_home .selected_items .card2 {
    margin-left: 20%;
  }
  .last_home .selected_items .card3 {
    margin-left: 30%;
  } */
  
  
  nav{
  
    position: absolute;
    right: 0;
  }
  }
  
  @media only screen and (max-width: 630px) {
   
   
    .last_header .header .menu_bar{
      position: absolute;
      background-color: #fff;
      margin-top: -41%;
      width: 25%;
      margin-left: 460px;
      height: 120px;
      border-radius: 10px;
      opacity: 1;
    }
  
     .last_header .header .menu_bar ul{
      opacity: 1;
    }
      .last_header .header .menu_bar ul li a{
        color: #000;
      }
       .last_header .header .menu_bar ul li a:hover{
        text-decoration: underline;
        color: #F08F33;
       }
   .last_header .header .menu_bar ul li {
    list-style: none;
    color: #000;
    opacity: 10;
    font-weight: bold;
    font-size: 15px;
    margin-left: 10px;
    padding-top: 10px;
   }
   .last_header .header .menu_bar ul{
      opacity: 1;
    }
      .last_header .header .menu_bar ul li a{
        color: #000;
      }
       .last_header .header .menu_bar ul li a:hover{
        text-decoration: underline;
        color: #F08F33;
       }
   .last_header .header .menu_bar ul li {
    text-align: start;
    list-style: none;
    color: #000;
    opacity: 10;
    font-weight: bold;
    font-size: 15px;
    margin-left: 10px;
    padding-top: 10px;
   }
   nav .icon{
    margin-top: -100px;
   }
   nav .menu ul li{
    display: none;
   }
   nav .search_input{
    width: 100%;
   }
   nav .bar {
   color: #fff;
   height: 40px;
   margin-right: 100px;
  }
  
  }
  @media only screen and (max-width: 580px) {
    .last_header .header .menu_bar{
      margin-left: 415px;
      margin-top: -250px;
      width: 27%;
    }
    nav .icon{
      margin-right: -30px;
    }
  
    nav .bar {
    color: #fff;
    
    height: 100px;
     }
       nav .search_input {
         margin-right: -100px;
       }
  }
  
  @media only screen and (max-width: 556px) {
  .last_header .header .menu_bar {
      margin-left: 405px;
      margin-top: -190px;
      width: 27%;
    }
  nav .search_input{
     height: 100px;
  
  }
  }
  @media only screen and (max-width: 530px) {
    .last_header .header .menu_bar {
        margin-left: 375px;
        margin-top: -190px;
        width: 28%;
      }
    nav{
     
    }
    nav .bar{
      margin-left: -20px;
    }
  
    nav .icon{
      margin-right: 10px;
     
    }
    nav .search_input Button{
      margin-right: -60px;
      width: 100px;
    }
    nav .search_input .fa{
      margin-left: 50px;
    }
  }
  
  @media only screen and (min-width: 650px) {
   
  
    nav .icon a{
      margin-left: 10%;
    }
    nav .icon img{
      width: 40%;
    }
    nav .bar{
      display: none;
    }
    nav .search_input ::placeholder {
         font-size: 15px;
    }
     nav .search_input input{
      padding-left: 13%;
    }
  }
  @media only screen and (min-width: 882px) {
  
    nav {
     margin-left: -170px;
  }
    nav .icon{
    
  }
   nav .icon img{
    margin-top: -20px;
  }
   nav .search_input {
  margin-right: -210px;
  width: 100%;
  
  height: 100px;
  padding-left: 20px;
   }
    nav .search_input input{
      width: 150px;
  }
  nav .bar{
    display: none;
  }
  nav .menu{
    margin-left:7%;
  }
  
  }
  @media only screen and (max-width: 882px) {
    .last_home .services{
      position: absolute;
      margin-top: 120px;
    }
      .last_home .some_btn {
        position: absolute;
        top: -40px;
         }
    .last_home .body{
      margin-top: 100px;
    }
    .last_home .right_side{
      margin-top:-470px;
    }
   .last_home .close {
          display: block;
          
        }
   .last_home .close .fa-solid{
          cursor: pointer;
        }
    .last_home .bars{
      margin-top: 280px;
    }
    .last_header .header {
        height: 250px;
      }
    nav{
      margin-left: 20px;
    }
    
    /* nav .icon{
      margin-left: 320px;
      height: 130px;
      margin-top: -120px;
     
      
    }
    nav .icon img{
      margin-top: -20px;
    }
      nav .icon a {
        margin-top: -10px;
        margin-left: -2px;
      } */
   nav .search_input{
   margin-top: 120px;
   width: 100%;
  }
  
  nav .search_input input{
     margin-left: -320px;
     width: 100%;
  
     
   }
    nav .dropdown{
      margin-left: -400px;
    }
    nav .menu{
      margin-right: 50px;
    }
  }
  @media only screen and (min-width: 768px) {
    .last_home .package .content .btn Button {
        margin-right: 60px;
        
    }
      .last_home .package .content .package_item {
        margin-right:20px;
      }
  }
  @media only screen and (max-width: 768px) {
    .last_home .package {
      height: 10%;
    }
    .last_home .package .content .btn Button{
       margin-right: 10px;
  
    }
    .last_home .package .content .package_item .selected_cards{
     width: 100%;
    
    }
  .last_home .package .content .package_item{
    margin-top: -200px;
    margin-left: 100px;
     }
   .last_home .package .content .last_btn {
    margin-top: -135px;
    }
    .last_home .package .content .last_btn Button{
      margin-left: 90%;
    }
    .last_home .some_btn {
          position: absolute;
          top: -730px;
        }
    .last_home .last_side_nav {
        display: none;
      }
    
      .last_home .only_icon {
        display: block;
      }
  nav .icon {
      margin-top: -20px;
      margin-left: 200px;
    }
  
    nav .icon img {
      width: 50%;
    }
  }
  @media only screen and (min-width: 730px) {
    nav .icon{
      margin-top: -20px;
      margin-left: 200px;
    }
    nav .icon img{
      width: 50%;
    }
  
  }
  
  
  @media only screen and (min-width: 940px) {
    nav .icon {
        margin-top: -20px;
      }
    nav .search_input input{
      padding-left: 8%;
    }
      nav .search_input {
        margin-left:6%;
      }
      
  }
  @media only screen and (max-width: 903px) {
    .last_home .package .content .selected_cards h6 {
      margin-top: 0px;
  
      }
  .last_home .package .content .selected_cards p {
      margin-left: 50%;
      margin-top: -200px;
    }
  }
  
  @media only screen and (max-width: 994px) {
    .last_home .package .content .package_item{
       margin-left: 200px;
    }
    .last_home .package .content .btn Button{
      margin-left: -700px;
    }
    
    .last_home .package .content .selected_cards p {
        margin-left: 50%;
        margin-top: -10px;
    }
  
  }
  @media only screen and (max-width: 1068px) {
    .last_home .package .content .last_btn{
      margin-right: 15px;
    }
    .last_home .package .content .package_item {
        margin-left: 170px;
      
       
      }
    .last_home .package .content .selected_cards p {
        margin-left: 50%;
        margin-top: -10px;
      }
       .last_home .package .content .btn Button{
      margin-left: -750px;
    
    }
  .last_home .content .circular{
    width: 250px;
    height: 250px;
    }
    .last_home .content .circular img{
      width: 350px;
    }
  
  
    .last_home .some_btn {
      position: relative;
      margin-top: 1300px;
    }
    .last_home .close{
      display: block;
    }
    .last_home .bars{
      display: block;
    }
  
  .last_home .right_side {
    margin-left: 35%;
    position: absolute;
    top: 1250px;
    width: 50%;
    
  }
  .last_home .right_side h5{
    margin-top: 2px;
    text-align: center;
  }
  .last_home .right_side img{
    margin-left: 45%;
    margin-top: 10px;
  }
  .last_home .right_side .rectangle img{
    margin-left: 35%;
  }
  .last_home .right_side  Button{
    margin-left: 25px;
    width: 40%;
  }
  
  .last_home .body{
   position: absolute;
   top: 160px;
   height: 70%;
    margin-left: 25%;
    width: 70%;
  }
  .last_home .services{
    position: absolute;
      top: 1320px;
    }
    .last_home .services .card{
      width: 100%;
      margin-left: 0px;
    }
    .last_home .new_nav{
      display: none;
    }
  }
  @media only screen and (min-width: 1068px) {
  .last_home .package .content .selected_cards p {
      margin-left: 70%;
      margin-top: -12px;
    }
    .last_home .body{
      margin-top: -700px;
    }
    
  .last_home .new_nav {
      display: block;
    }
    nav .icon {
        margin-top: -40px;
       }
    nav .icon a{
     margin-left: -5px;
     margin-top: 100px;
    }
    nav .icon img{
      margin-top: 0%;
    }
      nav .search_input {
        margin-left: 6%;
        padding-left: 10px;
      }
       nav .search_input input {
        width: 250px;
  }
      nav .search_input .fa {
        margin-left: 10px;
      }
  
  }
  @media only screen and (max-width: 1100px) {
  .home_footer .part_1 input {
    margin-left: 30px;
  width: 10%;
  }
  .home_footer .part_1 Button {
    margin-left: 285px;
  }
  }
  @media only screen and (min-width: 1200px) {
    nav .icon {
        margin-top: -10%;
        margin-left: 200px;
      }
      nav .icon img{
        margin-top: 90px;
        width: 40%;
      }
      nav .menu{
        margin-left: 5px;
      }
     
    nav .search_input {
       margin-left:1%;
       margin-right: -200px;
       padding-left: 100px;
     
  }
  
   nav .search_input input{
  width: 400px;
   }
   nav .menu{
    width: 100%;
   }
   nav .menu ul li{
    
   }
  }
  nav .drop{
    display: none;
  }
  
  .last_header .icon img{
    width: 70%;
    height: 50%;
   
  margin-top: 35%;
    margin-left: 10%;
  }
  
  nav .search_input .join_now{
    width: 140px;
    height: 45px;
    margin-left: 100px;
   
    background-color: #fff;
    color: #000;
    padding-left: 1%;
    padding-right: 1%;
  
  }
  nav .search_input span .fa-solid{
    color: #fff ;
    margin-left: 14px;
    font-size: 30px;
    margin-top: 5px;
  }
  
  .last_header nav .menu{
  height: 30px;
  display: flex;
  width: 30%;
  
  }
  .last_header nav .menu ul{
    display:flex;
    margin-left: -20%;
    list-style: none;
  }
  .last_header nav .menu ul li a{
  text-decoration: none;
  padding-left: 2px;
  color: #fff;
  margin-right: 15px;
  
  }
  nav .menu .fa-solid{
    color: #fff;
    font-size: large;
  }
  nav .bar .fa-solid{
    font-size: 23px;
    margin-left: -10%;
    cursor: pointer;
  }
  
  .selected_items{
    margin-left: 4.9%;
    margin-top: 0%;
    width: 92%;
    height: 7%;
    background-color: #fff;
  }
  .selected_items p{
    margin-left: 23%;
    margin-top: 8%;
   }
  
   .selected_items button{
    width: 100px;
    margin-top: -500px;
    background-color: #FAFAFA;
  
    
  }
  
  .last_home .selected_items{
    margin-top: 30px;
    margin-left: 1.7%;
    background-color: #EEEEEE;
    width: 90%;
    height: 230px;
  
  
  }
  .selected_items .btn_two{
    margin-left: 10px;
    width: 150px;
  }
  
  .categories .selected_cards {
    background-color: #fff;
    display: inline-block;
    height: 150px;
    margin-top: 50px;
    border-radius: 10px;
    width: 18%;
    margin-left: 1.5%;
  
  }
  
  .last_home .selected_items .selected_cards{
    background-color: #fff;
    display: inline-block;
    height: 150px;
      margin-top: 20px;
      border-radius: 10px;
      width: 18%;
      margin-left: 1.5%;
     
    }
    .categories h5{
      
    }
    
    .categories{
      
      margin-top: 20px;
        margin-left: 2.3%;
        background-color: #EEEEEE;
        width: 95%;
        height: 250px;
       
    }
      .categories .selected_cards img {
        width: 70%;
        height: 80%;
        margin-left: 10%;
        padding-top: 5%;
    }
    .last_home .selected_items .selected_cards img{
     width: 70%;
    height: 80%;
    margin-left: 10%;
    padding-top: 5%;
   
    }
    .categories .selected_cards .names p {
      padding-top: 50px;
      text-align: center;
      margin-left: -60px;
    }
  
  .last_home .selected_items .selected_cards .names p{
    padding-top: 20px;
    margin-left: 70px;
    
  }
  
  .last_home .cloths{
      margin-left: 1.5%;
      width: 100%;
  }
  .last_home .cloths h6{
    margin-left: 50px;
    margin-top: 50px;
  }
  .last_home .cloths .cloths_img .cloths_name .rate {
  margin-left: 7%;
  }
  .last_home .cloths .cloths_img {
    background-color: #EEEEEE;
    margin-left: 3%;
    margin-top: 2%;
    width: 16%;
    height: 160px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .last_home .cloths_img img {
    width: 70%;
    margin-left: 20px;
  
  
  }
  
  .last_home .cloths .cloths_name {
    background-color: #fff;
    padding-bottom: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 150px;
  
  }
  
  .last_home .cloths .cloths_name p {
    margin-left: 10%;
    padding-top: 10px;
  }
  
  .last_home .high_rating{
    margin-left: -10px;
    width: 100%;
   
  
  }
  .last_home .high_rating .high_rating_img{
   
    display: inline-block;
    margin-left: 3%;
    margin-top: 2%;
    width: 17%;
    height: 200px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .last_home .high_rating_img img{
    width: 100%;
    height: 80%;
    margin-left: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
    
  }
  .last_home .high_rating .high_rating_name{
    background-color: #EEEEEE;
    padding-bottom: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 150px;
    
  }
  .last_home .high_rating .high_rating_name p{
    margin-left: 10%;
    padding-top: 10px;
  }
  .last_home .high_rating .high_rating_img .high_rating_name .rate{
    margin-left: 7%;
  }
  .last_home .package .content{
      margin-top: 1px;
      margin-left: 11%;
      height: 280px;
      background-color: #F08F33;
      
  }
  .last_home .package{
    height: 280px;
  }
  .last_home .package .content .btn Button{
    margin-left: -400px;
    background-color: #D9D9D9;
    color: #000;
    font-size: 150%;
    border-radius: 100px;
    margin-top: 150px;
  
  }
  .last_home .package .content .last_btn{
    margin-top: -90px;
  }
  
  .last_home .package .content .last_btn Button{
      margin-left: 90%;
      background-color: #D9D9D9;
      color: #000;
      font-size: 150%;
      padding-top: 10px;
      border-radius: 100px;
      margin-top: -500px;
     
      
  }
  .last_home .package .content .last_btn  .fa-solid{
  margin-top: -100px;
  }
  .last_home .package .content .package_item{
     margin-left: 30px;
     margin-top: -100px;
    
     width: 70%;
  }
  .last_home .package .content .selected_cards{
    background-color: #F5F5F5;
      display: inline-block;
      height: 150px;
      margin-top: 10px;
      border-radius: 10px;
      width: 21.5%;
      margin-left: 2%;
  }
  .last_home .package .content .package_item{
    width: 70%;
    margin-left: 280px;
  }
  .last_home .package .content .selected_cards img{
    width: 70%;
      height: 70%;
      margin-left: 8%;
  }
  .last_home .package .content .selected_cards h6{
    font-size: 90%;
    margin-top: 10%;
    width: 100%;
  }
  .last_home .package .content .selected_cards p {
    font-size: 90%;
    margin-top: -11%;
    margin-left: 70%;
    width: 100%;
    color: #000;
  }
  .last_home .package .content .package_items {
    background-color: #D9D9D9;
    display: inline-block;
    margin-left: 2%;
    height: 300px;
    width: 25%;
    border-radius: 10px;
  }
  .last_home .package .content .package_item img {
  margin-left: 30px;
  padding-top: 10px;
  }
  .last_home .package .content .package_item .names h6{
    margin-left: 10px;
  }
  .last_home .package .content .package_item .names p{
    margin-left: 580px;
    margin-top: -20px;
  }
  .last_home .package .content .package_items img{
    width: 60%;
    height: 60%;
    margin-left: 30px;
    margin-top: 10px;
  }
  
  .container-fluid .all_products
  {
    background-color: #FAFAFA;
    width: 100%;
    height: 1000px;
  }
  .all_products .all_product{
    width: 15%;
    margin-top: 10px;
    height: 140px;
    background-color: #FAFAFA;
    display: inline-block;
    margin-left: 20px;
    border-radius: 10px;
  
  }
  .all_products .all_product img{
  width: 100%;
  height: 140px;
  border-radius: 10px;
  }
  .all_products .all_product h6{
    margin-top: 10px;
    margin-left: 10px;
  }
  .category{
   margin-top: 100px;
   margin-left: 50px;
  }
  .suppliers .all_suppliers {
    padding-top: 50px;
    background-color: #FAFAFA;
    margin-top: 100px;
    height: 300px;
  }
  .suppliers .all_suppliers h6{
  margin-left: 31px;
  }
  
  .suppliers .all_suppliers .each_suppliers{
    background-color: #DFE0EB;
    display: inline-block;
    border-radius: 10px;
    height: 150px;
   margin-left: 30px;
    width: 15%;
  }
  .suppliers .all_suppliers .each_suppliers img{
    width: 100%;
    height: 150px;
    border-radius: 10px;
  }
  .suppliers .all_suppliers .each_suppliers h5{
    padding-top: 10px;
    text-align: center;
  }
  
  .category h5{
    margin-left: 30px;
    font-weight: bold;
    font-size: 30px;
  }
  .category h6{
    margin-top: -40px;
    margin-left: 300px;
  }
  .all_products .all_product p{
   text-align:left;
    margin-left:5px;
  }
  .main_page .page_card .first_card{
    display: block;
    background-color: #fff;
    width: 12%;
    height: 150px;
    }
  .main_page .page_card .fourth_card{
      height: 600px;
      width: 45%;
      margin-left: 180px;
      margin-top:-10%;
      
    }
  .main_page .page_card .fourth_card img{
      height: 600px;
        width: 100%;
    }
  .main_page .page_card .first_card img{
      margin-left: 0px;
      width: 100%;
      height: 150px;
    }
    .main_page .site-layout{
      /* margin-top: -1850px; */
      margin-left: 100px;
    }
    .main_page .container-fluid{
      background-color: #FAFAFA;
      height: 2000px;
    }
    .main_page .footer{
      margin-top: -100px;
    }
    .page_card{
      margin-left: 100px;
      margin-top: 40px;
    
      
    }
    .page_card .first_card{
      margin-top: 15px;
        background-color: #F0F0F0;
    }
    .page_card .second_card {
      margin-top: 15px;
        background-color: #F0F0F0;
    }
    .page_card .third_card {
      margin-top: 15px;
      background-color: #F0F0F0;
    }
    .page_card .fourth_card {
      margin-top: -480px;
      margin-left: 140px;
      background-color: #F0F0F0;
    }
    .asbeza{
      margin-left: 900px;
      margin-top: -500px;
    }
    .asbeza h2{
      margin-left: 50px;
      font-size: 30px;
    }
    .asbeza .birr{
      margin-top: -50px;
      margin-left: 16px;
    }
    .asbeza p{
      margin-top: 50px;
    }
    .colors{
      margin-left: 900px;
      margin-top: 40px;
    }
    .colors .all_colors{
      margin-left: 80px;
      margin-top: -24px;
    }
    .buttons{
      margin-left: 930px;
      margin-top: 50px;
      border: 1px solid #000;
      width: 150px;
      height: 36px;
    
    
    }
    .buttons .main_btn{
    
    margin-left: 107px;
    margin-top: 0px;
    
    
    }
    .buttons .sub_1{
      margin-top: -32px;
      margin-left: 0px;
    }
    .buttons p{
      margin-left: 70px;
      margin-top: -27px;
     }
     .last_button{
       margin-left: 930px;
       margin-top: 50px;
    
     }
     .secondary{
       margin-left: 30px;
       width: 150px;
      height: 50px;
     }
     .primary{
       width: 230px;
       height: 50px;
     }
     .last_button .fa-solid{
       margin-left: -70px;
       
     }
     .main_products{
       margin-top: 200px;
       margin-left: 30px;
     }
    
    
    
     .main_products title {
       margin-left: -100px;
     }
    
     .main_products .stars {
       margin-left: -21px;
     }
    
     .main_products .stars .fa-solid {
       color: #F4AD33;
       margin-left: 20px;
       margin-top: 20px;
     }
    
     .main_products p {
       margin-top: -20px;
     }
    
     .main_products .bag .fa-solid {
       margin-top: 20px;
       margin-left: 180px;
     }
    .last_products{
      margin-top: 250px;
    }
    .last_header .search_input .badge {
      background-color: #E94042;
      height: 20px;
      border-radius: 100%;
    }
  
    .cart_page .product_card{
      margin-top: 70px;
      
  
      
       }
    .cart_page .orders{
      width: 23%;
      position: absolute;
      margin-left: 70%;
      top: 0;
      margin-top: 300px;
    }
    .cart_page .product_card img{
      height: 180px;
    }
    .cart_page .content{
      margin-left: 10%;
      margin-top: 40px;
    }
       .cart_page h5{
         margin-top: 2%;
         
       }
       .cart_page .content a{
         margin-left: 2%;
         margin-top: -4%;
         text-decoration: underline;
         color: #F4AD33;
       }
       .cart_page .orders{
        position: absolute;
        margin-left: 70%;
        top: 0;
        margin-top: 340px;
      }
  
      .orders .fourth_card{
        background-color: #FAFAFA;
        width: 120%;
      }
      .orders .fourth_card img{
        width: 100%;
        height: 100px;
      }
      .new_navs{
        margin-top: 2%;
        width:20%;
        left:1%;
      }
      .new_navs .ant-menu>.ant-menu-item {
        color: #262626;
        font-size: 12px;
        left:20px;
        padding-top: 3%;
      }
      
      .new_navs .ant-menu>.ant-menu-item-selected {
        color: #FAAD14 !important;
        border-bottom: 3px solid #FAAD14;
        /* background-color: red; */
      }
      
      .new_navs .ant-menu>.ant-menu-item:hover {
        color: #FAAD14 !important;
      }
  
  
      .update_profiler .spacing{
        min-width: 50%;
        max-width: 70%;
        margin-top: 2%;
        margin-left: 25%;
      }
  
      .update_profiler .spacing .profile_update{
        margin-left: 37.5%;
      }
      .update_profiler .spacing .profile_update_reset{
        margin-left: 1%;
        
      }
  
    .hcontents h6 i{
      color:green;
    }
  
    .fulfillmentTable{
        width:90%;
        margin-left: 5%;
        margin-top: 1%;
    }
  
    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 2px solid #F1AC1D !important; 
      z-index: 2;
      font-size: large;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-title{
      font-size: large !important;
      color:#FAAC1D !important;
    }
    .ant-tabs-tab-title{
      font-size: large !important;
      color:#FAAC1D !important;
    }

    .ant-tabs-tab-btn{
      padding-left: 12px;
      height: 20px;
      margin-top: 30px;
    }






  .table-row-red {
      background-color: #fbacac;
  }
  .table-row-green {
      background-color:#d7fee5;
  }
  .table-row-yellow{
    background-color: #fef3c7;
  }
  .table_with_notification{
    background-color: #fcd34d;
  }